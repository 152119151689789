define("discourse/plugins/dsc-show-link/discourse/initializers/dsc-show-link", ["exports", "discourse/lib/ajax", "discourse/lib/plugin-api"], function (_exports, _ajax, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable no-unused-vars */
  /* eslint-disable no-console */
  /* eslint-disable no-alert */

  const h = require("virtual-dom").h;
  var _default = _exports.default = {
    name: "dsc-show-link",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.7", api => {
        const site_Settings = api.container.lookup("site-settings:main");
        api.onPageChange((url, title) => {
          const router = api.container.lookup("service:router");
          const router_name = router.currentRoute.name;
          if ((router_name === "topic.fromParamsNear" || router_name === "topic.fromParams") && site_Settings.show_hide_link_from_all === true) {
            // let post_link_container = document.getElementsByClassName("post-links-container");
            let post_link_container = document.querySelector('.post-links-container');
            // console.log("post_link_container",post_link_container);
            if (post_link_container) {
              // let postLinks = post_link_container.querySelector('.post-links');
              let postLinks1 = document.querySelector('.post-links');
              if (postLinks1) {
                postLinks1.style.display = 'none';
              }
            }
          }
        });
      });
    }
  };
});